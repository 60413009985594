var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.order)?_c('div',{staticClass:"order-items"},[_c('div',{staticClass:"elevation-form pa-3"},[_c('div',{staticClass:"d-flex align-center mb-6"},[_c('h4',[_vm._v("Items ("+_vm._s(_vm.order.paidInfo.items.length)+")")])]),_c('v-divider',{staticClass:"my-5"}),_c('v-simple-table',[_c('tbody',[_vm._l((_vm.order.paidInfo.items),function(item){return [(item.sku !== 'tip')?_c('tr',{key:item.item_id},[_c('td',[_c('v-badge',{attrs:{"bordered":"","color":"#dddddd","content":item.qty,"overlap":""}},[_c('div',{staticClass:"image-item"},[(!item.image)?_c('img',{staticStyle:{"width":"50px"},attrs:{"src":'@/assets/images/no-image.png',"alt":""}}):_c('img',{staticStyle:{"width":"100%"},attrs:{"src":_vm.imageHelpers.url(item.image)}})])])],1),_c('td',{staticClass:"py-3"},[_c('router-link',{attrs:{"to":{ name: 'website.products.update', params: { id: item.product_id } }}},[_vm._v(" "+_vm._s(item._title)+" ")]),_c('div',[_vm._v(_vm._s(item._variant_variantTitle))]),_vm._l((item.personalizeText),function(personalize,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(personalize.name ? personalize.name : 'Name')+": "+_vm._s(personalize.text)+" ")])}),_c('div',[_vm._v("SKU:"+_vm._s(item._productSku ? item._productSku : ''))]),(
                  item._vendor === 'personalbridge' &&
                    item.attributes &&
                    item.attributes.find(function (i) { return i.key === 'custom_info'; })
                )?_c('div',_vm._l((item.attributes.find(function (i) { return i.key === 'custom_info'; }).value[0].custom),function(info,index){return _c('div',{key:index + 'info'},[(info.type && info.type === 'text')?_c('div',[_vm._v(_vm._s(info.title)+": "+_vm._s(info.value))]):_vm._e()])}),0):_vm._e(),(item._vendor === 'personalbridge')?_c('div',{staticClass:"preview py-1"},[_c('v-btn',{staticClass:"mr-2",attrs:{"depressed":"","color":"primary","small":""},on:{"click":function($event){return _vm.openPreview(item)}}},[_vm._v(" Mockup Custom ")]),(
                    item &&
                      item.attributes &&
                      item.attributes.length > 0 &&
                      item.attributes.find(function (i) { return i.key === 'print_files'; }) &&
                      item.attributes.find(function (i) { return i.key === 'print_files'; }).value[0] &&
                      item.attributes.find(function (i) { return i.key === 'print_files'; }).value[0].url &&
                      item._vendor === 'personalbridge'
                  )?_c('v-btn',{attrs:{"target":"_blank","href":item.attributes.find(function (i) { return i.key === 'print_files'; }).value[0].url,"color":"primary","small":""}},[_vm._v(" Design Custom ")]):_vm._e()],1):_vm._e()],2),_c('td',{staticStyle:{"width":"100px"}},[_vm._v(_vm._s(_vm.currency.priceFormat(item.price))+" x "+_vm._s(item.qty))]),_c('td',[_vm._v(_vm._s(_vm.currency.priceFormat(parseFloat(item.price * item.qty).toFixed(2))))])]):_vm._e(),(item.sku === 'tip')?_c('tr',{key:item.item_id},[_c('td',[_c('v-badge',{attrs:{"bordered":"","color":"#dddddd","content":item.qty,"overlap":""}},[_c('div',{staticClass:"image-item"},[(!item.image)?_c('img',{staticStyle:{"width":"50px"},attrs:{"src":'@/assets/images/no-image.png',"alt":""}}):_c('img',{staticStyle:{"width":"100%"},attrs:{"src":_vm.imageHelpers.url(item.image)}})])])],1),_c('td',{staticClass:"py-3"},[_c('span',[_vm._v(" "+_vm._s(item._title)+" ")]),_c('div',[_vm._v(_vm._s(item._variant_variantTitle))]),_vm._l((item.personalizeText),function(personalize,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(personalize.name ? personalize.name : 'Name')+": "+_vm._s(personalize.text)+" ")])}),_c('div',[_vm._v("SKU:"+_vm._s(item._productSku ? item._productSku : ''))])],2),_c('td',{staticStyle:{"width":"100px"}},[_vm._v(_vm._s(_vm.currency.priceFormat(item.price))+" x "+_vm._s(item.qty))]),_c('td',[_vm._v(_vm._s(_vm.currency.priceFormat(parseFloat(item.price * item.qty).toFixed(2))))])]):_vm._e()]})],2)])],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }