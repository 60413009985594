<template>
  <div>
    <div class="my-10">
      <i class="fas fa-chart-line"></i>
      <span class="title-info-customer"> Timeline </span>
    </div>
    <v-divider class="mb-4"></v-divider>
    <v-timeline style="margin-left: -47px" dense clipped>
      <v-slide-x-transition group>
        <div v-for="(item, ind1) in orderFulfill" :key="ind1">
          <v-timeline-item class="pb-0" hide-dot>
            <strong>
              {{
                parseInt(
                  moment()
                    .tz(pagePreference.standardTimezone ? pagePreference.standardTimezone : 'America/Los_Angeles')
                    .format('YYYYMMDD'),
                ) -
                  parseInt(
                    moment(item.originalDate)
                      .tz(pagePreference.standardTimezone ? pagePreference.standardTimezone : 'America/Los_Angeles')
                      .format('YYYYMMDD'),
                  ) ==
                0
                  ? 'Today'
                  : parseInt(
                      moment()
                        .tz(pagePreference.standardTimezone ? pagePreference.standardTimezone : 'America/Los_Angeles')
                        .format('YYYYMMDD'),
                    ) -
                      parseInt(
                        moment(item.originalDate)
                          .tz(pagePreference.standardTimezone ? pagePreference.standardTimezone : 'America/Los_Angeles')
                          .format('YYYYMMDD'),
                      ) ==
                    1
                  ? 'Yesterday'
                  : moment(item.originalDate)
                      .tz(pagePreference.standardTimezone ? pagePreference.standardTimezone : 'America/Los_Angeles')
                      .format('MMM DD')
              }}
            </strong>
          </v-timeline-item>
          <v-timeline-item v-for="(itemm, ind2) in item.body" :key="ind2" class="pb-0" small color="primary">
            <v-row class="my-0">
              <v-col cols="8" v-if="itemm.content.length > 6">{{ itemm.content }}</v-col>
              <v-col cols="8" class="" v-else>{{ itemm.content }} USD payment was processed</v-col>
              <v-col cols="4" class="text-center">
                {{
                  pagePreference.standardTimezone
                    ? moment(itemm.time)
                        .tz(pagePreference.standardTimezone)
                        .format('HH:mm:ss')
                    : moment(itemm.time)
                        .tz('America/Los_Angeles')
                        .format('HH:mm:ss')
                }}
              </v-col>
            </v-row>
          </v-timeline-item>
        </div>
      </v-slide-x-transition>
    </v-timeline>
  </div>
</template>
<script>
import datetime from '@/helpers/datetime';
import { orderApi } from '@/apis/order';
import moment from 'moment';
import { createLogger, mapState } from 'vuex';
import { FETCH_PAGE_PREFERRENCE } from '@/store/actions.type';
export default {
  props: {
    order: {
      required: true,
    },
  },
  data() {
    return {
      moment,
      datetime,
      orderFulfill: '',
      orderId: '',
      timezone: '',
    };
  },
  async beforeMount() {
    if (!this.pagePreference.standardTimezone) {
      this.getTimezoneDone = false;
      await this.$store.dispatch(FETCH_PAGE_PREFERRENCE);
      this.getTimezoneDone = true;
    }
  },
  created() {
    setTimeout(() => {
      this.getTimeline();
    }, 20);
  },
  methods: {
    async getTimeline() {
      try {
        this.orderId = this.$route.params.id;
        this.timezone = this.pagePreference.standardTimezone;
        // console.log(this.timezone);
        let data = await orderApi.getTimelineByOrderId(this.orderId, this.timezone);
        let b = data.data.histories;
        console.log(b);
        this.orderFulfill = Object.values(
          b.reduce((o, i, k) => {
            if (typeof o[i.createdDate.slice(0, 10)] === 'undefined') {
              o[i.createdDate.slice(0, 10)] = {
                createdDate: i.createdDate.slice(0, 10),
                body: [],
                time: [],
                originalDate: i.createdDate,
              };
            }
            if (localStorage.getItem('currencySign') === 'đ') {
              o[i.createdDate.slice(0, 10)].body.push({
                content: i.body.replace('$', 'đ').replace('USD', 'VND'),
                time: i.createdDate,
              });
            } else {
              o[i.createdDate.slice(0, 10)].body.push({ content: i.body, time: i.createdDate });
            }
            return o;
          }, {}),
        );
        console.log(this.orderFulfill);
        console.log(data);
      } catch (error) {}
    },
  },
  computed: {
    ...mapState({
      pagePreference: state => state.pagePreference.preference,
    }),
    timeline() {
      let transactions = this.order.transactions.map(item => item);
      return transactions;
    },
    paypalTransactionId() {
      return 0;
    },
  },
};
</script>
