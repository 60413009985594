<template>
  <div class="order-items mt-3" v-if="order">
    <div class="elevation-form pa-3">
      <div class="d-flex align-center mb-6">
        <!-- <v-avatar size="32" class="mr-2">
          <img :src="imageFake.unfulfilled" alt="" />
        </v-avatar> -->
        <h4>Fulfillment</h4>
      </div>
      <v-divider class="my-5"></v-divider>
      <div v-if="order.fulfillmentStatus !== 'fulfilled'" class="text-right">
        <v-btn color="primary" @click="$router.push({ name: 'website.orders.fulfillment' })">Add Tracking</v-btn>
      </div>
      <v-simple-table v-if="order.fulfillments && order.fulfillments.length > 0">
        <thead>
          <tr>
            <th class="text-left">
              Tracking Number
            </th>
            <th class="text-left">
              Tracking Carrier
            </th>
            <th class="text-left">
              Items
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(item, index) in order.fulfillments">
            <tr :key="`${index}-1`">
              <td>
                <a v-if="item.trackingUrl" :href="item.trackingUrl" target="_blank">
                  {{ item.trackingNumber }}
                </a>
                <span v-else>{{ item.trackingNumber }}</span>
              </td>
              <td>
                <div v-if="item.trackingCompany && carriers.find(c => c.value === item.trackingCompany)">
                  {{ carriers.find(c => c.value === item.trackingCompany).name }}
                </div>
              </td>
              <td>
                <div v-for="(lineItem, i) in item.lineItems" :key="i" class="py-3">
                  <div v-if="lineItem.id && getItem(lineItem.id)" class="d-flex">
                    <v-badge bordered color="#dddddd" :content="lineItem.quantity" overlap
                      ><div class="image-item">
                        <img width="60" :src="imageHelpers.url(getItem(lineItem.id).image)" />
                      </div>
                    </v-badge>
                    <div class="ml-3">
                      <router-link
                        :to="{ name: 'website.products.update', params: { id: getItem(lineItem.id).product_id } }"
                      >
                        {{ getItem(lineItem.id)._title }}
                      </router-link>
                      <div>{{ getItem(lineItem.id)._variant_variantTitle }}</div>
                      <!-- <div>SKU:{{ getItem(lineItem.id)._productSku ? getItem(lineItem.id)._productSku : '' }}</div> -->
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr :key="`${index}-2`">
              <td>
                <v-btn @click="cancelFulfill(item._id)" :loading="isLoadingAction" elevation="" color="" class="mr-2">
                  Cancel fulfillment
                </v-btn>
              </td>
              <td></td>
              <td class="text-right py-3">
                <v-btn @click="onShowDialogTracking(item)" :loading="isLoadingAction">
                  {{ item.trackingNumber ? 'Edit Tracking' : 'Add Tracking' }}
                </v-btn>
              </td>
            </tr>
          </template>
        </tbody>
      </v-simple-table>
      <div v-else class="text-center">
        No Tracking information
      </div>
    </div>

    <modal model="showDialogTracking" :title="`Tracking`" :callback="onSubmitAddTracking" v-if="showDialogTracking">
      <template slot="content">
        <div>
          <label for="">Tracking numbers</label>
          <v-text-field
            @blur="$v.tracking.$touch()"
            v-model="$v.tracking.trackingNumber.$model"
            :error-messages="
              $v.tracking.trackingNumber.$dirty && !$v.tracking.trackingNumber.required
                ? 'Tracking number is required'
                : ''
            "
          ></v-text-field>
        </div>
        <div>
          <label for="">Carrier</label>
          <select name="Carrier" id="Carrier" class="form-control" v-model="tracking.trackingCompany">
            <option v-for="(carrier, index) in carriers" :key="index" :value="carrier.value">{{ carrier.name }}</option>
          </select>
        </div>
        <div>
          <v-checkbox v-model="sendEmail" label="Send notification email to customer"></v-checkbox>
        </div>
      </template>
    </modal>
  </div>
</template>
<script>
import { imageFake } from '@/const/image';
import imageHelpers from '@/helpers/image';
import { required, minLength, maxLength, between, email } from 'vuelidate/lib/validators';
import { orderApi } from '@/apis/order';

export default {
  props: {
    order: {
      type: Object,
    },
  },
  validations: {
    tracking: {
      trackingNumber: {
        required,
      },
    },
  },
  data() {
    return {
      imageHelpers,
      showDialogTracking: false,
      tracking: {
        trackingCompany: '',
        trackingNumber: '',
      },
      fulFillmentId: '',
      sendEmail: true,
      carriers: [
        {
          name: 'DHL',
          value: 'DHL_GLOBAL_MAIL',
        },
        {
          name: '4PX',
          value: 'FOUR_PX_EXPRESS',
        },
        {
          name: 'AU Post',
          value: 'AUSTRALIA_POST',
        },
        {
          name: 'USPS',
          value: 'USPS',
        },
        {
          name: 'Fedex',
          value: 'FEDEX',
        },
        {
          name: 'EMS',
          value: 'CHINA_POST',
        },
        {
          name: 'CA Post',
          value: 'CANADA_POST',
        },
        {
          name: 'UPS',
          value: 'UPS',
        },
        {
          name: 'OnTrac',
          value: 'US_ONTRAC',
        },
        {
          name: 'DHL eCommerce US',
          value: 'DHL_GLOBAL_MAIL',
        },
        {
          name: 'DHL Global eCommerce',
          value: 'DHL_GLOBAL_ECOMMERCE',
        },
        {
          name: 'Yanwen',
          value: 'YANWEN_CN',
        },
        {
          name: 'China Post',
          value: 'CHINA_POST',
        },
        {
          name: 'Malaysia Post EMS',
          value: 'MALAYSIA_POST',
        },
        {
          name: 'Epacket',
          value: 'EMS_CN',
        },
        {
          name: 'EMS China',
          value: 'EMS_CN',
        },
        {
          name: 'HONGKONG POST',
          value: 'HK_POST',
        },
        {
          name: 'SF EXPRESS',
          value: 'SF_EXPRESS',
        },
        {
          name: 'Singapore Post',
          value: 'SINGPOST',
        },
        {
          name: 'Russia Post',
          value: 'RUSSIAN_POST',
        },
        {
          name: 'Poste Italiane',
          value: 'POSTE_ITALIANE',
        },
        {
          name: 'LA Poste',
          value: 'LAPOSTE',
        },
        {
          name: 'Other',
          value: 'OTHER',
        },
      ],
      isLoadingAction: false,
    };
  },
  methods: {
    getItem(id) {
      let item = this.order.paidInfo.items.find(i => i.item_id === id);
      return item;
    },
    onShowDialogTracking(item) {
      this.showDialogTracking = true;
      this.tracking.trackingNumber = item.trackingNumber;
      this.tracking.trackingCompany = item.trackingCompany;
      this.fulFillmentId = item._id;
    },
    async onSubmitAddTracking() {
      this.$v.tracking.$touch();
      if (!this.$v.tracking.$invalid) {
        try {
          this.isLoadingAction = true;
          await orderApi.updateFulfillment(this.order.id, this.fulFillmentId, this.tracking);
          this.isLoadingAction = false;
          this.showDialogTracking = false;
          this.fulFillmentId = null;
          this.$emit('reload');
        } catch (error) {
          console.log(error);
        }
      }
    },
    async cancelFulfill(idFulfill) {
      this.isLoadingAction = true;
      try {
        await orderApi.cancelFulfillment(this.order.id, idFulfill);
        this.$emit('reload');
        this.isLoadingAction = false;
      } catch (error) {
        this.isLoadingAction = false;
      }
    },
  },
};
</script>
