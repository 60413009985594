<template>
  <div class="elevation-form pa-3 mt-3">
    <div class="d-flex align-center mb-6 mr-2">
      <v-avatar size="32" class="mr-2">
        <img v-if="order.financialStatus === 'paid'" :src="'@/assets/images/checked.png'" alt="" />
        <img v-else :src="imageFake.unfulfilled" alt="" />
      </v-avatar>
      <h4>{{ order.financialStatus }}</h4>
    </div>
    <div v-for="item in order.items" :key="item.item_id"></div>
    <v-row no-gutters class="d-flex align-center">
      <v-col cols="5">Subtotal</v-col>
      <v-col cols="2">{{ order.paidInfo.items_qty }} item</v-col>
      <v-col cols="5" class="d-flex justify-end">{{ currency.priceFormat(order.paidInfo.sub_total) }}</v-col>
    </v-row>
    <v-row no-gutters class="d-flex align-center my-3">
      <v-col cols="5">Shipping</v-col>
      <v-col cols="5">{{ getShippingTitle }}</v-col>
      <v-col v-if="order.paidInfo.shipping_total > 0" cols="2" class="d-flex justify-end">
        {{ currency.priceFormat(order.paidInfo.shipping_total) }}
      </v-col>
      <v-col v-else cols="2" class="d-flex justify-end">FREE</v-col>
    </v-row>
    <v-row v-if="order.paidInfo.discount_amount > 0" no-gutters class="d-flex align-center my-3">
      <v-col cols="5">Discount</v-col>
      <v-col cols="5"></v-col>
      <v-col cols="2" class="d-flex justify-end">- {{ currency.priceFormat(order.paidInfo.discount_amount) }}</v-col>
    </v-row>
    <v-row v-if="order.paidInfo.tax && order.paidInfo.tax > 0" no-gutters class="d-flex align-center my-3">
      <v-col cols="5">Tax</v-col>
      <v-col cols="5"></v-col>
      <v-col cols="2" class="d-flex justify-end">{{
        currency.priceFormat(parseFloat(order.paidInfo.tax).toFixed(2))
      }}</v-col>
    </v-row>
    <v-row no-gutters class="d-flex align-center">
      <v-col cols="5" style="font-weight: 700">Total</v-col>
      <v-col cols="5"> </v-col>
      <v-col style="font-weight: 700" cols="2" class="d-flex justify-end">{{
        currency.priceFormat(order.paidInfo.grand_total)
      }}</v-col>
    </v-row>
    <v-divider class="my-5"></v-divider>
    <v-row>
      <v-col cols="5"> Paid by customer </v-col>
      <v-col cols="5"></v-col>
      <v-col cols="2" class="text-right">{{ currency.priceFormat(order.grandTotal) }}</v-col>
    </v-row>
    <v-row v-if="order.totalRefund">
      <v-col cols="5"> Refunded </v-col>
      <v-col cols="5">
        <div v-for="refunded in $parent.$refs['order-refunded'].refunds" :key="refunded._id">
          {{ refunded.reason }}
        </div>
      </v-col>
      <v-col cols="2" class="text-right">{{ currency.priceFormat(parseFloat(order.totalRefund).toFixed(2)) }}</v-col>
    </v-row>
    <v-row v-if="order.totalRefund">
      <v-col cols="5"> Net Payment </v-col>
      <v-col cols="5"></v-col>
      <v-col v-if="order.totalRefund" cols="2" class="text-right">
        {{ currency.priceFormat(parseFloat(order.grandTotal - order.totalRefund).toFixed(2)) }}
      </v-col>
      <v-col v-else cols="2" class="text-right">{{
        currency.priceFormat(parseFloat(order.grandTotal).toFixed(2))
      }}</v-col>
    </v-row>
  </div>
</template>
<script>
import { imageFake } from '@/const/image';
import currency from '@/helpers/currency';

export default {
  props: {
    order: {
      required: true,
    },
  },
  data() {
    return {
      imageFake,
      currency,
    };
  },
  computed: {
    getShippingTitle() {
      // console.log('this.order.paidInfo.shipping_title', this.order.paidInfo.shipping_title);
      return this.order.paidInfo.shipping_total > 0 ? '' : this.order.paidInfo.shipping_title;
    },
  },
};
</script>
