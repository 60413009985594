<template>
  <div>
    <v-card v-if="profit && order">
      <v-card-title><strong>Your Profit</strong></v-card-title>
      <v-card-text style="color: #333;">
        <v-row no-gutters class="mb-2">
          <v-col xs="8"><strong>Revenue</strong></v-col>
          <v-col xs="4" class="text-right">{{ currency(revenue) }}</v-col>
        </v-row>
        <div class="pl-4" style="color: #555;">
          <v-row no-gutters class="mb-2">
            <v-col xs="8">Sub total</v-col>
            <v-col xs="4" class="text-right">{{ currency(subTotal) }}</v-col>
          </v-row>
          <v-row no-gutters class="mb-2">
            <v-col xs="8">Discount</v-col>
            <v-col xs="4" class="text-right">- {{ currency(discount) }}</v-col>
          </v-row>
          <v-row no-gutters class="mb-2">
            <v-col xs="8">Tip</v-col>
            <v-col xs="4" class="text-right">{{ currency(tip) }}</v-col>
          </v-row>
          <v-row no-gutters class="mb-2">
            <v-col xs="8">Tax</v-col>
            <v-col xs="4" class="text-right">{{ currency(tax) }}</v-col>
          </v-row>
        </div>
        <v-divider class="mb-2" />
        <v-row no-gutters class="mb-2">
          <v-col xs="8"><strong>Shipping fee</strong></v-col>
          <v-col xs="4" class="text-right">{{ currency(adjustmentShippingFee) }}</v-col>
        </v-row>
        <div class="pl-4" style="color: #555;">
          <v-row no-gutters class="mb-2">
            <v-col xs="8">Adjustment shipping fee</v-col>
            <v-col xs="4" class="text-right">{{ currency(adjustmentShippingFee) }}</v-col>
          </v-row>
        </div>
        <v-divider class="mb-2" />
        <v-row no-gutters class="mb-2">
          <v-col xs="8"><strong>Base cost</strong></v-col>
          <v-col xs="4" class="text-right">- {{ currency(baseCost) }}</v-col>
        </v-row>
        <v-divider class="mb-2" />
        <v-row no-gutters class="mb-2">
          <v-col xs="8"><strong>Handling fee</strong></v-col>
          <v-col xs="4" class="text-right">- {{ currency(handlingFee) }}</v-col>
        </v-row>
        <div class="pl-4" style="color: #555;">
          <v-row no-gutters class="mb-2">
            <v-col xs="8">Payment fee</v-col>
            <v-col xs="4" class="text-right">- {{ currency(paymentFee) }}</v-col>
          </v-row>
          <v-row no-gutters class="mb-2">
            <v-col xs="8">Processing fee</v-col>
            <v-col xs="4" class="text-right">- {{ currency(processingFee) }}</v-col>
          </v-row>
          <v-row no-gutters class="mb-2">
            <v-col xs="8">Latte fee</v-col>
            <v-col xs="4" class="text-right">- {{ currency(latteFee) }}</v-col>
          </v-row>
        </div>
        <v-divider class="mb-2" />
        <v-row no-gutters class="mb-2">
          <v-col xs="8"><strong>Profit</strong></v-col>
          <v-col xs="4" class="text-right">{{
            `${this.disputeAmount && !this.source.reslove_dispute_amount ? '-' : ''} ${currency(totalProfit)}`
          }}</v-col>
        </v-row>
        <v-divider class="mb-2" style="display: none" />
        <v-row no-gutters class="mb-2" style="display: none">
          <v-col xs="8"><strong>Hold</strong></v-col>
          <v-col xs="4" class="text-right">{{ currency(hold) }}</v-col>
        </v-row>
        <v-divider class="mb-2" v-if="disputeReason && !this.source.reslove_dispute_amount" />
        <v-row no-gutters class="mb-2" v-if="disputeReason && !this.source.reslove_dispute_amount">
          <v-col xs="8"><strong>Dispute</strong></v-col>
        </v-row>
        <div class="pl-4" style="color: #555;" v-if="disputeReason && !this.source.reslove_dispute_amount">
          <!-- <v-row no-gutters class="mb-2">
            <v-col xs="8">Amount</v-col>
            <v-col xs="4" class="text-right"> {{ currency(disputeAmount) }}</v-col>
          </v-row> -->
          <v-row no-gutters class="mb-2">
            <v-col xs="8">Reason</v-col>
            <v-col xs="4" class="text-right"> {{ disputeReason }}</v-col>
          </v-row>
        </div>
        <v-divider
          class="mb-2"
          v-if="order && (order.financialStatus == 'refunded' || order.financialStatus == 'partially_refunded')"
        />
        <v-row
          no-gutters
          class="mb-2"
          v-if="order && (order.financialStatus == 'refunded' || order.financialStatus == 'partially_refunded')"
        >
          <v-col xs="8"><strong>Refund</strong></v-col>
        </v-row>
        <div
          class="pl-4"
          style="color: #555;"
          v-if="order && (order.financialStatus == 'refunded' || order.financialStatus == 'partially_refunded')"
        >
          <v-row no-gutters class="mb-2">
            <v-col xs="8">Reason</v-col>
            <v-col xs="4" class="text-right"> {{ this.source.reason || 'Refunded' }}</v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { orderApi } from '@/apis/order';
import currency from '@/helpers/currency';

export default {
  props: {
    orderId: String,
    order: Object,
  },
  data() {
    return {
      profit: null,
    };
  },
  computed: {
    source() {
      return this.profit?.hits?.hits[0]?._source || {};
    },
    revenue() {
      return this.source.revenue || 0;
    },
    subTotal() {
      return Number(this.source.sub_total || 0);
    },
    discount() {
      return Number(this.source.discount_amount || 0);
    },
    tip() {
      let items = this.order?.items || [];
      let val = 0;
      for (let it of items) {
        if (it.sku == 'tip' || it._sku == 'tip' || it.variant_sku == 'tip') {
          val = Number(it.row_total || 0);
          break;
        }
      }
      return val;
    },
    tax() {
      return Number(this.order?.paidInfo?.tax || 0);
    },
    shippingFee() {
      return Number(this.source.shipping_total || 0);
    },
    adjustmentShippingFee() {
      return Number(this.source.adjustmentShippingFee || 0);
    },
    baseCost() {
      return Number(this.source.totalBasecost || 0);
    },
    handlingFee() {
      return Number(this.source.handlingFee || 0);
    },
    paymentFee() {
      return Number(this.source.paymentFee || 0);
    },
    processingFee() {
      return Number(this.source.processingFee || 0);
    },
    totalProfit() {
      return Number(this.source.profit || 0) + Number(this.source.orderHold || 0);
    },
    latteFee() {
      return Number(this.source.orderLatteFee || 0);
    },
    hold() {
      return Number(this.source.orderHold || 0);
    },
    disputeAmount() {
      return Number(this.source.dispute_amount || 0);
    },
    disputeReason() {
      return this.source.dispute_reason || '';
    },
  },
  methods: {
    async loadProfit() {
      try {
        let res = await orderApi.getProfit(this.orderId);
        this.profit = res.data || {};
      } catch (err) {}
    },
    currency(val) {
      return currency.priceFormat(val);
    },
  },
  mounted() {
    this.loadProfit();
  },
};
</script>
