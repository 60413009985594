<template>
  <div class="order-items" v-if="order">
    <div class="elevation-form pa-3">
      <div class="d-flex align-center mb-6">
        <!-- <v-avatar size="32" class="mr-2">
          <img :src="imageFake.unfulfilled" alt="" />
        </v-avatar> -->
        <h4>Items ({{ order.paidInfo.items.length }})</h4>
      </div>
      <v-divider class="my-5"></v-divider>
      <v-simple-table>
        <tbody>
          <template v-for="item in order.paidInfo.items">
            <tr v-if="item.sku !== 'tip'" :key="item.item_id">
              <td>
                <v-badge bordered color="#dddddd" :content="item.qty" overlap
                  ><div class="image-item">
                    <img v-if="!item.image" style="width: 50px" :src="'@/assets/images/no-image.png'" alt="" />
                    <img style="width: 100%" v-else :src="imageHelpers.url(item.image)" />
                  </div>
                </v-badge>
              </td>
              <td class="py-3">
                <router-link :to="{ name: 'website.products.update', params: { id: item.product_id } }">
                  {{ item._title }}
                </router-link>
                <div>{{ item._variant_variantTitle }}</div>
                <div v-for="(personalize, index) in item.personalizeText" :key="index">
                  {{ personalize.name ? personalize.name : 'Name' }}: {{ personalize.text }}
                </div>
                <div>SKU:{{ item._productSku ? item._productSku : '' }}</div>
                <div
                  v-if="
                    item._vendor === 'personalbridge' &&
                      item.attributes &&
                      item.attributes.find(i => i.key === 'custom_info')
                  "
                >
                  <div
                    v-for="(info, index) in item.attributes.find(i => i.key === 'custom_info').value[0].custom"
                    :key="index + 'info'"
                  >
                    <div v-if="info.type && info.type === 'text'">{{ info.title }}: {{ info.value }}</div>
                  </div>
                </div>
                <div class="preview py-1" v-if="item._vendor === 'personalbridge'">
                  <v-btn depressed class="mr-2" color="primary" small @click="openPreview(item)">
                    Mockup Custom
                  </v-btn>
                  <v-btn
                    target="_blank"
                    :href="item.attributes.find(i => i.key === 'print_files').value[0].url"
                    v-if="
                      item &&
                        item.attributes &&
                        item.attributes.length > 0 &&
                        item.attributes.find(i => i.key === 'print_files') &&
                        item.attributes.find(i => i.key === 'print_files').value[0] &&
                        item.attributes.find(i => i.key === 'print_files').value[0].url &&
                        item._vendor === 'personalbridge'
                    "
                    color="primary"
                    small
                  >
                    Design Custom
                  </v-btn>
                </div>
              </td>
              <td style="width: 100px">{{ currency.priceFormat(item.price) }} x {{ item.qty }}</td>
              <td>{{ currency.priceFormat(parseFloat(item.price * item.qty).toFixed(2)) }}</td>
            </tr>
            <tr v-if="item.sku === 'tip'" :key="item.item_id">
              <td>
                <v-badge bordered color="#dddddd" :content="item.qty" overlap
                  ><div class="image-item">
                    <img v-if="!item.image" style="width: 50px" :src="'@/assets/images/no-image.png'" alt="" />
                    <img style="width: 100%" v-else :src="imageHelpers.url(item.image)" />
                  </div>
                </v-badge>
              </td>
              <td class="py-3">
                <span>
                  {{ item._title }}
                </span>
                <div>{{ item._variant_variantTitle }}</div>
                <div v-for="(personalize, index) in item.personalizeText" :key="index">
                  {{ personalize.name ? personalize.name : 'Name' }}: {{ personalize.text }}
                </div>
                <div>SKU:{{ item._productSku ? item._productSku : '' }}</div>
              </td>
              <td style="width: 100px">{{ currency.priceFormat(item.price) }} x {{ item.qty }}</td>
              <td>{{ currency.priceFormat(parseFloat(item.price * item.qty).toFixed(2)) }}</td>
            </tr>
          </template>
        </tbody>
      </v-simple-table>
    </div>
  </div>
</template>
<script>
import { imageFake } from '@/const/image';
import imageHelpers from '@/helpers/image';
import currency from '@/helpers/currency';

export default {
  props: {
    order: {
      type: Object,
    },
  },
  data() {
    return {
      imageHelpers,
      imageFake,
      currency,
    };
  },
};
</script>
