<template>
  <div class="order-page--detail">
    <v-row no-gutters>
      <v-col cols="12">
        <redirect class="mb-5" title="Orders" route="website.orders" />
        <div class="page-header" v-if="data">
          <div class="order-title d-flex justify-start align-center">
            <h2>ORDER: {{ data.name }}</h2>
            <h4>
              <v-chip class="ml-2" :color="data.financialStatus | colorSwitcher" label outlined small>
                {{ data.financialStatus | capitalize }}
              </v-chip>
              <v-chip :color="data.fulfillmentStatus | colorSwitcher" label outlined small>
                {{ data.fulfillmentStatus | capitalize }}
              </v-chip>
              <v-chip label outlined small>
                {{ data.paymentStatus | capitalize }}
              </v-chip>
            </h4>
          </div>

          <!-- {{ pagePreference.standardTimezone }} -->
          <div class="my-3">
            {{
              moment(data.capturedDate)
                .tz(pagePreference.standardTimezone ? pagePreference.standardTimezone : 'America/Los_Angeles')
                .format('DD-MM-YYYY HH:mm:ss')
            }}
            from Online store
          </div>
          <div class="d-flex align-center">
            <!-- <v-btn class="mr-2">
              <v-icon small class="mr-2">fas fa-print</v-icon>
              Print Order
            </v-btn> -->
            <v-btn
              v-if="
                (parseFloat(data.totalRefund) < parseFloat(data.paidInfo.grand_total) || !data.totalRefund) &&
                  data.paymentName != 'cod' &&
                  data.isTestOrder == false
              "
              @click="$router.push({ name: 'website.orders.refund', params: { id: data.id } })"
              class="mr-2"
            >
              <v-icon small class="mr-2">fas fa-money-bill-wave</v-icon>
              Refund item
            </v-btn>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="mr-2" v-bind="attrs" v-on="on"> More actions </v-btn>
              </template>
              <v-list>
                <v-list-item v-if="data.financialStatus !== 'canceled'">
                  <v-list-item-title @click="cancelOrder">
                    <v-icon class="mr-2" small>fas fa-times-circle</v-icon>
                    Cancel Order
                  </v-list-item-title>
                </v-list-item>
                <!-- <v-list-item>
                  <v-list-item-title>
                    <v-icon class="mr-2" small>fas fa-trash</v-icon>
                    Archive
                  </v-list-item-title>
                </v-list-item> -->
                <v-list-item>
                  <v-list-item-title @click="goToOrderDetail">
                    <v-icon class="mr-2" small>far fa-list-alt</v-icon>
                    View order status page
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="loading">
      <v-col cols="12">
        <SkeletonLoaders type="card-heading, list-item-three-line" />
      </v-col>
    </v-row>
    <!-- {{ data.isTestOrder }} -->
    <v-row class="notice-testgateway my-4 mx-0" v-if="!loading">
      <v-col cols="12" class="px-0" v-if="data.isTestOrder">
        <div class="d-flex">
          <div class="mx-3 mt-1"><v-icon color="#ffdd38">mdi mdi-information-outline mdi-20px</v-icon></div>
          <div>
            <h3>Test order</h3>
            <div>
              Your payment gateway was in test mode when this order was created. All fees are for demo only and won’t be
              charged on test orders.
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="!loading">
      <v-col v-if="data" cols="12" sm="8" class="d-flex flex-column">
        <Items :order="data" />

        <Tracking :order="data" @reload="getData" />
        <!-- <Fulfillment :order="data" @reload="getData" /> -->
        <!--Refunded là partial_refunded ở trên timeline, còn Refund là btn Refund item -->
        <Refunded ref="order-refunded" :order="data" />
        <!-- mấy cái Subtotal Shipping Total Paid by customer ở dưới -->
        <div
          v-if="themeName === 'book' && data.itemsInfo && data.itemsInfo.length > 0"
          class="elevation-form pa-3 mt-3 form-component"
        >
          <h3>Detail book</h3>
          <div class="" v-if="data.itemsInfo">
            <div v-for="(item, j) in data.itemsInfo" :key="j">
              <div class="my-3">
                <h4>Book : {{ item.title }}</h4>
              </div>
              <template v-if="item && item.product_option">
                Characters :
                <span v-for="(i, k) in item.product_option.characters" :key="k">
                  {{ i.name }}
                </span>
              </template>
            </div>
          </div>
        </div>
        <Transaction :order="data" :afterRefundItem="afterRefundItem" />
        <profit-pane v-if="isDropship" class="my-3" :order-id="$route.params.id" :order="data" />
        <TimeLine :order="data" />
      </v-col>
      <v-col v-if="data.customer" cols="12" sm="4" class="d-flex flex-column">
        <!-- <div class="elevation-form pa-3">
          <ProductAvailability />
        </div> -->
        <div class="elevation-form pa-3">
          <div class="d-flex justify-space-between">
            <h4>Notes</h4>
            <span class="edit-label">Edit</span>
          </div>
          <div class="d-flex justify-space-between" v-if="data && data.customerInfo[0] && data.customerInfo[0].note">
            {{ data.customerInfo[0].note }}
          </div>
          <div class="d-flex justify-space-between" v-else>No notes from customer</div>
        </div>
        <v-divider></v-divider>
        <div class="elevation-form pa-3 mt-3 form-component">
          <div class="">
            <h4>Customer</h4>
          </div>
          <div class="d-flex">
            <v-avatar>
              <img :src="imageFake.user" alt="" />
            </v-avatar>
            <div class="ml-2">
              <div class="customer-name">{{ data.customer.full_name }}</div>
            </div>
          </div>
          <!-- <div class="d-flex justify-space-between">
            <span class="edit-label">Edit</span>
            No notes from customer
          </div> -->
        </div>
        <v-divider></v-divider>
        <div class="elevation-form pa-3 py-5">
          <div class="d-flex justify-space-between">
            <span class="title-info-customer">CONTACT INFORMATION</span>
            <span @click="showModalEditCustomer('contact')" class="edit-label">Edit</span>
          </div>
          <div class="d-flex justify-space-between">
            {{ data.customerInfo[0].email }}
          </div>
        </div>
        <v-divider></v-divider>
        <div class="elevation-form pa-3 py-5">
          <div class="d-flex justify-space-between">
            <span class="title-info-customer">SHIPPING ADDRESS</span>
            <span @click="showModalEditCustomer('shipping')" class="edit-label">Edit</span>
          </div>
          <div v-if="data && data.customerInfo[0]" class="">
            <p class="mb-1">{{ data.customerInfo[0].full_name }}</p>
            <p class="mb-1">{{ data.customerInfo[0].customerAttribute.address1 }}</p>
            <p class="mb-1">{{ data.customerInfo[0].customerAttribute.city }}</p>
            <p class="mb-1">{{ data.customerInfo[0].customerAttribute.province }}</p>
            <p class="mb-1">
              {{ data.customerInfo[0].customerAttribute.country }}
            </p>
            <p class="mb-1">
              {{ data.customer.country_code }}
            </p>
            <p class="mb-1">{{ data.customerInfo[0].phone }}</p>
            <!-- <i class="far fa-clone mr-3"></i> -->
          </div>
        </div>
        <v-divider></v-divider>
        <div class="elevation-form pa-3 py-5">
          <div class="d-flex justify-space-between">
            <span class="title-info-customer">BILLING ADDRESS </span>
          </div>
          <div class="d-flex justify-space-between">Same as shipping address</div>
          <!-- <div v-if="data && data.customerInfo[0]" class="mt-2">
            <p class="mb-1">{{ data.customerInfo[0].full_name }}</p>
            <p class="mb-1">{{ data.customerInfo[0].customerAttribute.address1 }}</p>
            <p class="mb-1">{{ data.customerInfo[0].customerAttribute.city }}</p>
            <p class="mb-1">{{ data.customerInfo[0].customerAttribute.province }}</p>
            <p class="mb-1">{{ data.customerInfo[0].customerAttribute.country }}</p>
            <p class="mb-1">{{ data.customerInfo[0].phone }}</p>
          </div> -->
        </div>
        <div class="elevation-form pa-3 py-5" v-if="data.trackingData">
          <div class="d-flex justify-space-between">
            <span class="title-info-customer">CONVERSION SUMMARY </span>
          </div>
          <div
            class="d-flex justify-space-between flex-column"
            v-if="data.trackingData.conversion && data.trackingData.conversion.source"
          >
            <p class="ma-0">Source identifier: {{ data.trackingData.conversion.source }}</p>
            <p class="ma-0">Refering site: {{ data.trackingData.conversion.refererSite }}</p>
            <p class="ma-0">First page: {{ data.trackingData.conversion.firstPage }}</p>
          </div>
          <div class="d-flex justify-space-between" v-else>No data</div>
        </div>
        <div class="elevation-form pa-3 py-5" v-if="data.trackingData">
          <div class="d-flex justify-space-between">
            <span class="title-info-customer">UTM PARAMETERS </span>
          </div>
          <div
            class="d-flex justify-space-between flex-column"
            v-if="data.trackingData.utmParams && data.trackingData.utmParams.source"
          >
            <p class="ma-0">Source: {{ data.trackingData.utmParams.source }}</p>
            <p class="ma-0">Medium: {{ data.trackingData.utmParams.medium }}</p>
            <p class="ma-0">Campaign: {{ data.trackingData.utmParams.campainId }}</p>
          </div>
          <div class="d-flex justify-space-between" v-else>No data</div>
        </div>
        <!-- <div class="elevation-form pa-3 mt-3">
          <h3 class="fz-12">Tags</h3>
          <v-combobox
            hide-selected
            multiple
            persistent-hint
            small-chips
            placeholder="Reviewed, packed, delivered...."
            hide-details
            append-icon=""
          >
          </v-combobox>
        </div> -->
        <div class="elevation-form pa-3 mt-3">
          <div class="">
            <h4>Fraud analysis</h4>
          </div>
          <div class="d-flex align-center my-4">
            <i class="fas fa-circle mr-3"></i>
            <div>Customer didn't use any web proxy service to place the order</div>
          </div>
          <div class="d-flex align-center my-5">
            <i class="fas fa-circle mr-3"></i>
            <div>A payment method other than a credit card was used</div>
          </div>
          <div class="link-add edit-label">
            <router-link :to="{ name: 'website.analytics' }">View full analysis</router-link>
          </div>
        </div>
      </v-col>
      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12" class="d-flex">
        <v-spacer></v-spacer>
        <v-btn readonly color="primary">Save</v-btn>
      </v-col>
    </v-row>
    <!-- <div style="width: 100%; height: 100%" v-if="loading" class="d-flex justify-center align-center">
      <page-loader />
    </div> -->
    <delete-action :content="'product ' + name" :title="'Delete ' + name" />
    <BeforeLeavePage />
    <EditCustomer @reloadOrder="getData" v-if="data" ref="modal-edit-customer" />
    <modal
      model="modalCancelOrder"
      title="Cancel order"
      width="900"
      :callback="onCancelOrder"
      titleButton="Cancel Order"
      :isLoading="isLoadingCancelOrder"
    >
      <template slot="content">
        <div>All payments will be voided. Canceling an order can’t be undone.</div>
        <div>
          <h3>Refund payments</h3>
        </div>
        <div v-if="data && data.paidInfo">
          <v-simple-table>
            <tbody>
              <tr v-for="item in itemsRefund" :key="item.item_id">
                <td>
                  <v-badge bordered color="#dddddd" :content="item.qty" overlap
                    ><div class="image-item">
                      <img width="50" v-if="!item.image" :src="'@/assets/images/no-image.png'" alt="" />
                      <img width="50" v-else :src="imageHelpers.url(item.image)" />
                    </div>
                  </v-badge>
                </td>
                <td class="py-3">
                  <router-link
                    v-if="item.sku !== 'tip'"
                    :to="{ name: 'website.products.update', params: { id: item.product_id } }"
                  >
                    {{ item._title }}
                  </router-link>
                  <span v-else>
                    {{ item._title }}
                  </span>
                  <div>{{ item._variant_variantTitle }}</div>
                  <div v-if="personalizeText(item)">
                    <div v-for="(personalize, index) in personalizeText(item)" :key="index">
                      {{ personalize.name ? personalize.name : 'Name' }}: {{ personalize.text }}
                    </div>
                  </div>
                  <div>SKU:{{ item._sku }}</div>
                </td>
                <td style="width: 100px">{{ currency.priceFormat(item.price) }} x {{ item.qty }}</td>
                <td>{{ currency.priceFormat((item.price * item.qty).toFixed(2)) }}</td>
              </tr>
              <!--  Discount -->
              <tr v-if="data.paidInfo.discount_amount > 0">
                <td></td>
                <td></td>
                <td>Discount</td>
                <td>{{ data.paidInfo.discount_amount }}</td>
              </tr>
              <!--  Subtotal -->
              <tr>
                <td></td>
                <td></td>
                <td>Subtotal</td>
                <td>{{ currency.priceFormat(subTotalRefund) }}</td>
              </tr>
              <!--  Shipping -->
              <tr v-if="data.paidInfo.shipping_total > 0">
                <td></td>
                <td></td>
                <td>Shipping {{ data.paidInfo.shipping_total }}</td>
                <td>
                  <v-text-field
                    style="width: 80px"
                    @change="changeShipping"
                    type="number"
                    v-model="shippingCancelRefund"
                    hide-details
                  ></v-text-field>
                </td>
              </tr>
              <!--  Tax -->
              <tr v-if="data.paidInfo.tax && data.paidInfo.tax > 0">
                <td></td>
                <td></td>
                <td>Tax</td>
                <td>{{ currency.priceFormat(data.paidInfo.tax) }}</td>
              </tr>
              <!--  Total available to refund -->
              <tr>
                <td></td>
                <td></td>
                <td style="width: 200px">Total available to refund</td>
                <td v-if="data.totalRefund">
                  {{ currency.priceFormat(parseFloat(data.grandTotal - data.totalRefund).toFixed(2)) }}
                </td>
                <td v-else>{{ currency.priceFormat(parseFloat(data.grandTotal).toFixed(2)) }}</td>
              </tr>
              <!--  Refund with: Paypal Express -->
              <tr>
                <td colspan="2">
                  <strong
                    >Refund{{
                      data.paymentName == 'stripe'
                        ? ' with: Stripe'
                        : data.paymentName == 'cod'
                        ? ''
                        : ' with: Paypal Express'
                    }}</strong
                  >
                </td>
                <!-- là số tiền lớn nhất có thể refund, totalRefund là computed-->
                <td>
                  <!-- <strong>{{ currency.priceFormat(parseFloat(totalRefund).toFixed(2)) }}</strong> -->
                </td>
                <!-- là số tiền muốn refund -->
                <td v-if="!loading">
                  <strong>
                    <v-text-field
                      @blur="checkRefundedAmout"
                      style="width: 80px"
                      type="number"
                      v-model="totalCancel"
                      hide-details
                    >
                    </v-text-field>
                  </strong>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </div>
        <div>
          <label for="">Reason for canceling this order</label>
          {{ reason }}
          <v-select single-line v-model="reason" :items="reasons" item-text="title" item-value="value"></v-select>
        </div>
        <div>
          <v-checkbox v-model="sendEmail" label="Send notification email to customer"></v-checkbox>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import utilities from '@/helpers/utilities';
import { required, minLength, maxLength, between, email } from 'vuelidate/lib/validators';
import { orderApi } from '@/apis/order';
import { imageFake } from '@/const/image';
import imageHelpers from '@/helpers/image';
import STORAGE_NAME from '@/const/storage';
import datetime from '@/helpers/datetime';
import Fulfillment from './components/Fulfillment';
import Refunded from './components/Refunded';
import TimeLine from './components/TimeLine';
import ProfitPane from './components/ProfitPane';
import Items from './components/Items';
import Tracking from './components/Tracking';
import EditCustomer from './components/EditCustomer';
import Transaction from './components/Transaction';
import { mapState } from 'vuex';
import moment from 'moment';
import currency from '@/helpers/currency';

export default {
  // mixins: [resourceBeforeLeavePage],
  components: {
    // Fulfillment,
    EditCustomer,
    TimeLine,
    ProfitPane,
    Transaction,
    Refunded,
    Items,
    Tracking,
  },
  data() {
    return {
      isLoadingCancelOrder: false,
      afterRefundItem: 0,
      moment,
      timeline: [],
      datetime,
      events: [],
      input: null,
      nonce: 0,
      imageFake,
      imagesSelect: [],
      isVariantDefault: false,
      showDialogDelete: false,
      name: '',
      idItem: null,
      isLoading: false,
      loading: false,
      page: {},
      showHeader: false,
      searchString: 'null hihi',
      listVariants: [],
      // Product options
      options: [], // need
      imageHelpers,
      data: null,
      totalPrice: 0,
      fulFillmentList: [],
      itemsRefunded: [],
      itemsRefund: [],
      modalCancelOrder: false,
      sendEmail: true,
      shippingCancelRefund: '',
      totalCancel: 0,
      totalRefund: '',
      reason: 'customer changed/canceled order',
      themeName: this.$store.state.pagePreference.preference.storeFrontDefaultTheme || `hydro`,
      reasons: [
        {
          title: 'Customer changed/canceled order',
          value: 'customer changed/canceled order',
        },
        {
          title: 'Fraudulent order',
          value: 'fraudulent order',
        },
        {
          title: 'Items unavailable',
          value: 'items unavailable',
        },
        {
          title: 'Payment declined',
          value: 'payment declined',
        },
        {
          title: 'Other',
          value: 'other',
        },
      ],
      currency,
    };
  },
  mounted() {
    this.themeName = this.$store.state.pagePreference.preference.storeFrontDefaultTheme || `hydro`;
  },
  async created() {
    await this.getData();
  },
  methods: {
    checkRefundedAmout() {
      if (parseFloat(this.totalCancel) > parseFloat(this.totalRefund) || parseFloat(this.totalCancel) <= 0) {
        this.totalCancel = parseFloat(this.totalRefund).toFixed(2);
      }
      if (!this.totalCancel) {
        this.totalCancel = 0;
      }
    },
    //reset phí ship khi nhập sai
    inputN() {
      console.log('inputShip');
    },
    changeShipping() {
      console.log('ChangeShip');
      if (
        parseFloat(this.shippingCancelRefund) > parseFloat(this.data.paidInfo.shipping_total) ||
        parseFloat(this.shippingCancelRefund) < 0
      ) {
        this.shippingCancelRefund = this.data.paidInfo.shipping_total;
        console.log(this.data.paidInfo.shipping_total);
        console.log(this.shippingCancelRefund);
      }
      if (this.shippingCancelRefund === '') {
        this.shippingCancelRefund = 0;
      }
      // if (!this.shippingCancelRefund) {
      //   this.shippingCancelRefund = 0;
      // }
      if (this.data && this.data.totalRefund) {
        this.totalRefund =
          parseFloat(this.data.paidInfo.sub_total) +
          parseFloat(this.shippingCancelRefund) -
          parseFloat(this.data.paidInfo.discount_amount) -
          parseFloat(this.data.totalRefund).toFixed(2);
      } else {
        this.totalRefund =
          parseFloat(this.data?.paidInfo?.sub_total || 0) +
          parseFloat(this.shippingCancelRefund) -
          parseFloat(this.data?.paidInfo?.discount_amount || 0).toFixed(2);
      }
    },
    goToOrderDetail() {
      let key = this.data.customerInfo && this.data.customerInfo.length ? this.data.customerInfo[0].key : '';
      window.open('https://' + this.$store.state.auth.accountInfo.rDomain + `/order/${this.data.id}?key=` + key);
    },
    async cancelOrder() {
      this.itemsRefunded = this.$refs['order-refunded'].itemsRefunded;
      this.reason = 'customer changed/canceled order'; // reset lai reason
      this.data.items.forEach(item => {
        let findItem = this.itemsRefunded.find(i => i.item_id == item.item_id);
        let obj = {};
        obj.item_id = item.item_id;
        obj.reason = this.reason;
        obj.price = item.price;
        obj.product_id = item.product_id;
        obj.image = item.image;
        obj.title = item.title;
        obj._title = item._title;
        obj._variant_variantTitle = item._variant_variantTitle;
        obj.price = item.price;
        obj._sku = item._sku;
        obj.sku = item.sku;
        if (findItem) {
          console.log(1);
          if (findItem.qty < item.qty) {
            console.log(2);
            obj.qty = item.qty - findItem.qty;
            this.itemsRefund.push(obj);
          }
        } else {
          console.log(3);
          if (!this.itemsRefund.find(item => item.item_id === obj.item_id)) {
            obj.qty = item.qty;
            this.itemsRefund.push(obj);
            console.log(4);
          }
        }
        // reset lại total cancel nếu k save
        this.totalCancel = this.totalRefund;
        console.log(this.itemsRefund);
      });
      this.shippingCancelRefund = this.data.paidInfo.shipping_total;
      this.modalCancelOrder = true;
    },
    async onCancelOrder() {
      this.isLoadingCancelOrder = true;
      let items = this.itemsRefund.map(item => {
        return {
          id: item.item_id,
          item_id: item.item_id,
          refunded_qty: item.qty,
        };
      });
      let data = {};
      data.order = this.data.id;
      data.store = this.data.storeId;
      data.items = items;
      data.grandTotal = this.totalCancel;
      data.subTotal = this.subTotalRefund;
      data.discountTotal = 0;
      data.shippingTotal = this.shippingCancelRefund;
      data.sendNotification = this.sendEmail;
      data.reason = this.reason;
      try {
        await orderApi.cancelOrder(data);
        this.isLoadingCancelOrder = false;
        this.modalCancelOrder = false;
        this.getData();
      } catch (error) {
        this.isLoadingCancelOrder = false;
        console.log(error);
      }
    },
    showModalEditCustomer(type) {
      if (type === 'contact') {
        this.$refs['modal-edit-customer'].title = 'Edit contact information';
        this.$refs['modal-edit-customer'].type = type;
      } else {
        this.$refs['modal-edit-customer'].title = 'Edit shipping information';
        this.$refs['modal-edit-customer'].type = type;
      }
      this.$refs['modal-edit-customer'].onShowModal();
    },
    async cancelFulfill(idFulfill) {
      try {
        await orderApi.cancelFulfillment(this.$route.params.id, idFulfill);
      } catch (error) {}
    },
    convertDataFulfillment(itemFulfill) {
      let obj = { ...itemFulfill, sum: 0, itemFulfill: [] };
      for (let i = 0; i < itemFulfill.lineItems.length; i++) {
        obj.sum = obj.sum + itemFulfill.lineItems[i].quantity;
        let product = this.data.itemsInfo.find(item => {
          return item.item_id === itemFulfill.lineItems[i].id;
        });
        if (product) {
          product.qty = itemFulfill.lineItems[i].quantity;
          obj.itemFulfill.push(product);
        }
      }
      return obj;
    },
    async getData() {
      try {
        this.loading = true;
        this.idItem = this.$route.params.id;
        let storeDomain = localStorage.getItem(STORAGE_NAME.STORE_DOMAIN);
        this.data = (await orderApi.getById(this.idItem)).data;

        if (this.data.trackingData.conversion.source == 'direct') {
          if (this.data.trackingData.conversion.firstPage.indexOf('fbclid') > -1)
            this.data.trackingData.conversion.source = 'facebook.com';
          if (this.data.trackingData.conversion.firstPage.indexOf('gclid') > -1)
            this.data.trackingData.conversion.source = 'google.com';
        }

        console.log(this.data);
        this.data.name = `${this.data.orderName}`;
        // this.data.items.forEach(element => {
        //   this.totalPrice = this.totalPrice + element.price;
        // });
        this.timeline = this.data.transactions;
        this.shippingCancelRefund = this.data.paidInfo.shipping_total;
        if (this.data.totalRefund) {
          this.totalRefund = (
            parseFloat(this.data.paidInfo.sub_total) +
            parseFloat(this.data.paidInfo.tax) +
            parseFloat(this.shippingCancelRefund) -
            parseFloat(this.data.paidInfo.discount_amount) -
            parseFloat(this.data.totalRefund)
          ).toFixed(2);
          this.totalCancel = this.totalRefund;
        } else {
          this.totalRefund = (
            parseFloat(this.data.paidInfo.sub_total) +
            parseFloat(this.data.paidInfo.tax) +
            parseFloat(this.shippingCancelRefund) -
            parseFloat(this.data.paidInfo.discount_amount)
          ).toFixed(2);
          this.totalCancel = this.totalRefund;
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error, 'error');
      }
      this.loading = false;
    },
    isPersonalizeProduct(item) {
      return Array.isArray(item?.personalizeText) && item?.personalizeText?.length > 0;
    },
    getPersonalizeTxt(item) {
      return item.personalizeText
        .map(item => {
          return item.text;
        })
        .join(',');
    },
    personalizeText(item) {
      let data = [];
      let findItem = this.data.paidInfo.items.find(i => i.item_id == item.item_id);
      data = findItem ? findItem.personalizeText : [];
      return data;
    },
    getTotalRefund() {
      if (this.data && this.data.totalRefund) {
        this.totalRefund =
          parseFloat(this.data.paidInfo.sub_total) +
          parseFloat(this.shippingCancelRefund) -
          parseFloat(this.data.paidInfo.discount_amount) -
          parseFloat(this.data.totalRefund);
      } else {
      }
      this.totalRefund =
        parseFloat(this.data?.paidInfo?.sub_total || 0) +
        parseFloat(this.shippingCancelRefund) -
        parseFloat(this.data?.paidInfo?.discount_amount || 0);
    },
  },
  computed: {
    isDropship() {
      return this.pagePreference && this.pagePreference.businessType === 'dropship';
    },
    subTotalRefund() {
      let total = this.itemsRefund.reduce((total, item) => {
        return total + item.price * item.qty;
      }, 0);
      return total.toFixed(2);
    },
    ...mapState({
      pagePreference: state => state.pagePreference.preference,
    }),
    availableRefundShippings() {
      let total = 0;
      if (this.$refs['order-refunded']) {
        total = this.$refs['order-refunded'].refunds.reduce((total, item) => {
          return parseFloat(total) * 1 + parseFloat(item.shippingTotal);
        }, 0);
      }
      return parseFloat(this.data.paidInfo.shipping_total - total).toFixed(2);
    },
  },
  watch: {
    attributes: {
      handler: function(val, oldVal) {
        this.showHeader = !utilities.comparisonTwoObject(this.defaultForm, this.attributes);
        // if (this.showHeader) {
        //   this.$v.$reset();
        // }
      },
      deep: true,
    },
    totalRefund() {
      this.totalCancel = parseFloat(this.totalRefund).toFixed(2);
    },
  },
  validations: {
    attributes: {
      title: {
        required,
        minLength: minLength(0),
        maxLength: maxLength(255),
      },
    },
  },
};
</script>
<style lang="scss">
.v-list-item {
  cursor: pointer;
}
.customer-name {
  color: $main-color;
}

.order-page--detail {
  .notice-testgateway {
    background-color: #fffbe6;
    color: #5f3200;
    border-left: 2px solid #ffdd38;
  }
  .btn-link {
    color: $main-color;
    &:hover {
      text-decoration: none;
    }
  }
  .edit-label {
    font-weight: 700;
    color: $main-color;
    cursor: pointer;
  }
  .image-item {
    width: 50px;
    height: 50px;
    border: 0.2px solid #dddd;
    margin-right: 10px;
  }
  .title-info-customer {
    font-weight: 700;
    line-height: 1rem;
    text-transform: uppercase;
    font-size: 12px;
  }
  .v-expansion-panel:before {
    box-shadow: none;
  }
  .v-expansion-panel-header__icon {
    position: absolute;
    right: 20px;
    top: 20px;
  }
}
</style>
