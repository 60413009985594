<template>
  <div class="elevation-form mt-3 pa-3" v-if="itemsRefunded && itemsRefunded.length > 0">
    <div class="d-flex align-center mb-6">
      <h4>Removed items({{ itemsRefunded.length }})</h4>
    </div>
    <v-divider class="my-5"></v-divider>
    <v-simple-table>
      <tbody>
        <tr v-for="item in itemsRefunded" :key="item.item_id">
          <td>
            <v-badge bordered color="#dddddd" :content="item.qty" overlap
              ><div class="image-item">
                <img v-if="!item.image" style="width: 50px" :src="'@/assets/images/no-image.png'" alt="" />
                <img style="width:100%" v-else :src="imageHelpers.url(item.image)" />
              </div>
            </v-badge>
          </td>
          <td class="py-3">
            <router-link
              v-if="item.sku !== 'tip'"
              :to="{ name: 'website.products.update', params: { id: item.product_id } }"
            >
              {{ item._title }}
            </router-link>
            <span v-else>
              {{ item._title }}
            </span>
            <div>{{ item._variant_variantTitle }}</div>
            <div v-if="personalizeText(item)">
              <div v-for="(personalize, index) in personalizeText(item)" :key="index">
                {{ personalize.name ? personalize.name : 'Name' }}: {{ personalize.text }}
              </div>
            </div>
            <div>SKU:{{ item._sku }}</div>
          </td>
          <td style="width: 100px;">{{ currency.priceFormat(item.price) }} x {{ item.qty }}</td>
          <td>{{ currency.priceFormat(parseFloat(item.price * item.qty).toFixed(2)) }}</td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>
<script>
import { orderApi } from '@/apis/order';
import { imageFake } from '@/const/image';
import imageHelpers from '@/helpers/image';
import currency from '@/helpers/currency';

export default {
  props: {
    order: {
      type: Object,
    },
  },
  data() {
    return {
      itemsRefunded: [],
      refunds: null,
      imageFake,
      imageHelpers,
      currency,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      let vm = this;
      try {
        let refundedData = await orderApi.getRefundedByOrderId(this.order.id);
        console.log(refundedData);
        let items = [];
        this.refunds = refundedData.data.refunds;
        refundedData.data.refunds.forEach(item => {
          item.items.forEach(element => {
            if (items.find(i => i.item_id === element.item_id)) {
              let index = items.findIndex(i => i.item_id === element.item_id);
              let quantity = items.find(i => i.item_id === element.item_id).refunded_qty + element.refunded_qty;
              if (index > -1) {
                vm.$set(items[index], 'refunded_qty', quantity);
              }
            } else {
              items.push(element);
            }
          });
        });
        this.order.items.forEach(item => {
          let findItem = items.find(i => i.item_id === item.item_id);
          if (findItem) {
            let obj = {};
            obj.item_id = item.item_id;
            obj.product_id = item.product_id;
            obj.image = item.image;
            obj.title = item.title;
            obj._title = item._title;
            obj._variant_variantTitle = item._variant_variantTitle;
            obj.price = item.price;
            obj._sku = item._sku;
            obj.sku = item.sku;
            obj.qty = findItem.refunded_qty;
            obj.personalizeText = item.personalizeText;
            this.itemsRefunded.push(obj);
          }
        });
        console.log(this.refunds);
        // this.itemsRefunded = items;
      } catch (error) {
        console.log(error);
      }
    },
    isPersonalizeProduct(item) {
      return Array.isArray(item?.personalizeText) && item?.personalizeText?.length > 0;
    },
    getPersonalizeTxt(item) {
      return item.personalizeText
        .map(item => {
          return item.text;
        })
        .join(',');
    },
    personalizeText(item) {
      let data = [];
      let findItem = this.data?.paidInfo?.items.find(i => i.item_id == item.item_id);
      data = findItem ? findItem.personalizeText : [];
      return data;
    },
  },
};
</script>
